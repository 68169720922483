import $ from 'jquery'

class Cart {
  constructor (options = {}) {
    this.reload_page_on_change = options.reload_page_on_change || false
    this.loadingMessage = '...'
    this.updateCartXhr = null
    this.orderItemsDisabled = false

    // Add event listeners when the dom is ready
    $(this.addEventListeners.bind(this))
  }

  addEventListeners () {
    this.removeEventListeners()
    // Add event listeners with a .cart namespace
    $('#store_cart_form_contract_period').on('change.cart', this.onUpdateCart.bind(this))
    $('#store_cart_form_discount_cents').on('change.cart', this.onUpdateCart.bind(this))
    $('#store_cart_form_discount_reason').on('change.cart', this.onUpdateCart.bind(this))
    $('#store_cart_form_additional_cost_cents').on('change.cart', this.onUpdateCart.bind(this))
    $('#store_cart_form_additional_cost_reason').on('change.cart', this.onUpdateCart.bind(this))

    $('#period a').on('click.cart', this.onPeriodClick.bind(this))
    $('#discount').on('input.cart', this.onDiscountChange.bind(this))
    $('#additional_cost').on('input.cart', this.onAdditionalCostChange.bind(this))
    $('.cart-product__remove a').on('click.cart', this.onOrderItemRemove.bind(this))
    $('.cart-products__hidden-toggle').on('click.cart', this.onHiddenToggleClick.bind(this))
  }

  removeEventListeners () {
    // Remove event listeners with the .cart namespace
    $('#store_cart_form_additional_cost_cents').off('.cart')
    $('#store_cart_form_additional_cost_reason').off('.cart')
    $('#store_cart_form_contract_period').off('.cart')
    $('#store_cart_form_discount_cents').off('.cart')
    $('#store_cart_form_discount_reason').off('.cart')

    $('#period a').off('.cart')
    $('#discount').off('.cart')
    $('#additional_cost').off('.cart')
    $('.cart-product__remove a').off('.cart')
    $('.cart-products__hiddent-toggle').off('.cart')
  }

  // This method handles all cart updates except changes related to order_items (remove)
  // We get the new cart prices back directly in the response, which the order_items endpoints do not
  onUpdateCart () {
    // Show loading message
    this.showLoadingMessage()

    // Get new cart values from the dom
    let cart = { store_cart_form: {} }
    cart.store_cart_form.store_id = $('#store_cart_form_store_id').val()
    cart.store_cart_form.associate_id = $('#store_cart_form_associate_id').val()
    cart.store_cart_form.contract_period = $('#store_cart_form_contract_period').val()
    cart.store_cart_form.discount_cents = $('#store_cart_form_discount_cents').val()
    cart.store_cart_form.discount_reason = $('#store_cart_form_discount_reason').val()
    cart.store_cart_form.additional_cost_cents = $('#store_cart_form_additional_cost_cents').val()
    cart.store_cart_form.additional_cost_reason = $('#store_cart_form_additional_cost_reason').val()

    // Abort any pending reqests to ensure that the latest request gets rendered last, e.g. when typing fast
    if(this.updateCartXhr) {
      this.updateCartXhr.abort()
    }

    // Save new cart
    this.updateCartXhr = $.ajax({
      cache: false,
      context: this,
      data: cart,
      dataType: 'json',
      method: 'PATCH',
      url: '/cart.json'
    })
    .done( function(response) {
      // Update the dom with the new prices
      this.renderPrices(response)
    })
    .fail( function(error) {
      // Ajax errors are handled globally
    })
  }

  onHiddenToggleClick (e) {
    e.preventDefault()

    // Toggle hidden content
    $('.cart-products__hidden-toggle').toggleClass('cart-products__hidden-toggle--open')
    $('.cart-products__hidden-content').toggle()
  }

  // This method will update the hidden select which then triggers onUpdateCart
  onPeriodClick (e) {
    e.preventDefault()

    // Update segmented control
    $('#period .segmented-control__active').removeClass('segmented-control__active')
    $(e.currentTarget).addClass('segmented-control__active')

    // Update hidden select
    const period = $(e.currentTarget).data('value')
    $('#store_cart_form_contract_period').val(period).trigger('change')
  }

  // This method will update the hidden input with the amount in cents which then triggers onUpdateCart
  onDiscountChange (e) {
    const discount = $('#discount').val()
    const cents = this.convertToCents(discount)

    // Abort any pending reqests to ensure that the latest request gets rendered last, e.g. when typing fast
    if(this.updateCartXhr) {
      this.updateCartXhr.abort()
    }

    // Update cents
    setTimeout(() => {  $('#store_cart_form_discount_cents').val(cents).trigger('change') }, 400);
  }

  // This method will update the hidden input with the amount in cents which then triggers onUpdateCart
  onAdditionalCostChange (e) {
    const additionalCost = $('#additional_cost').val()
    const cents = this.convertToCents(additionalCost)

    // Abort any pending reqests to ensure that the latest request gets rendered last, e.g. when typing fast
    if(this.updateCartXhr) {
      this.updateCartXhr.abort()
    }

    // Update cents
    setTimeout(() => { $('#store_cart_form_additional_cost_cents').val(cents).trigger('change') }, 400);
  }

  onOrderItemRemove (e) {
    e.preventDefault()
    e.stopPropagation()

    // Limit count/remove to one request at a time to ensure that all of them complete
    if(this.orderItemsDisabled) {
      return
    } else {
      this.disableOrderItems()
    }

    // Get order item id from the dom and remove the item
    const tradeInItemId = $(e.currentTarget).closest('.cart-product').data('orderTradeInItemId')
    const orderItemId = $(e.currentTarget).closest('.cart-product').data('orderItemId')

    // Show loading message
    this.showLoadingMessage()

    if(orderItemId){
      this.removeOrderItem(orderItemId)
    }else if(tradeInItemId){
      this.removeTradeInItem(tradeInItemId);
    }
  }

  removeTradeInItem(tradeInItemId) {
    const $item = $('.cart-product[data-order-trade-in-item-id=' + tradeInItemId + ']')

    $item.remove()

    const path = '/trade_ins/items/';

    this.deleteAjaxCall(path, tradeInItemId)
  }

  removeOrderItem(orderItemId) {
    // Find item in the dom
    const $item = $('.cart-product[data-order-item-id=' + orderItemId + ']')

    // Find any associated items
    const $associatedItems = $('.cart-product[data-parent-order-item-id=' + orderItemId + ']')

    // Remove the original item from the dom
    $item.remove()

    // Remove associated items from the dom
    if($associatedItems.length > 0) {
      $associatedItems.remove()
    }

    const path = '/order_items/';

    this.deleteAjaxCall(path, orderItemId)
  }

  deleteAjaxCall(path, id){
    // Save changes to server
    $.ajax({
      cache: false,
      context: this,
      method: 'DELETE',
      url:  path + id + '.json'
    })
    .done( function(response) {
      // Refresh page if there are no products left
      if($('.cart-product').length < 1) {
        window.location.reload()
      // Otherwise fetch new prices
      } else {
        this.getPrices()
      }
    })
    .fail( function(error) {
      // Ajax errors are handled globally
    })
  }

  showLoadingMessage () {
    const loadingMessage = this.loadingMessage

    // The loading messages will be replaced once the new values have loaded
    $('.js__upgrade-period').html( loadingMessage )
    $('.js__loan-period').html( loadingMessage )
    $('.js__monthly-cost').html( loadingMessage )
    $('.js__bank-service-fee').html( loadingMessage )
    $('.js__starting-fee').html( loadingMessage )
    $('.js__interest-rate').html( loadingMessage )
    $('.js__product-monthly-cost').html( loadingMessage )
    $('.js__product-monthly-cost-all').html( loadingMessage )
    $('.js__product-value').html( loadingMessage )
    $('.js__product-residual-value').html( loadingMessage )
    $('.js__product-upgrade-period').html( loadingMessage )
    $('.js__total-value-of-cart').html( loadingMessage )
    $('.js__amount-paid-at-upgrade').html( loadingMessage )
    $('.js__effective-interest-rate').html( loadingMessage )
    $('.js__amount-paid-without-upgrade').html( loadingMessage  )

    $('.js__products-addons-name').html( loadingMessage )
    $('.js__product-addons-sku').html( loadingMessage )

    $('.js__lender-specific-insurance-cost-monthly').html( loadingMessage  )
    $('.js__lender-specific-insurance-cost-total').html( loadingMessage  )

    $('.js__lender-specific-dll-insurance-cost-monthly').html( loadingMessage  )
    $('.js__lender-specific-dll-insurance-cost-total').html( loadingMessage  )

    $('.js__lender-specific-insurance-cost-monthly').html( loadingMessage  )
    $('.js__lender-specific-insurance-cost-total').html( loadingMessage  )

    $('.renew_it_plus--without-insurance-names').html( loadingMessage  )
  }

  disableOrderItems () {
    this.orderItemsDisabled = true
    $('.cart-product').addClass('cart-product--disabled')
  }

  enableOrderItems () {
    this.orderItemsDisabled = false
    $('.cart-product').removeClass('cart-product--disabled')
  }

  // removeItem calls this method on success to fetch the latest prices
  // All other updates post to cart.json and get the new prices back as a response directly
  getPrices () {
    // Show loading message
    this.showLoadingMessage()

    if(this.reload_page_on_change){
      window.location.reload();
    }else{
      // Get cart data
      $.ajax({
        cache: false,
        context: this,
        dataType: 'json',
        method: 'GET',
        url: '/cart.json'
      })
      .done( function(response) {
        // Update the dom with the new prices
        this.renderPrices(response)

        // Enable order items controls again
        this.enableOrderItems()

        // Update cart count in topbar
        this.updateTopbarCartCount()
      })
      .fail( function(error) {
        // Ajax errors are handled globally
      })
    }
  }

  renderAllowedContractPeriods (data) {
    $('.js__allowed-contract-periods').html('');

    data.allowed_contract_periods.forEach(function(contract_period){
      let _class = '';
      if(contract_period == data.contract_period){
        _class = 'segmented-control__active'
      }

      $('.js__allowed-contract-periods').append('<li><a href="#" data-value="' + contract_period + '" class="' + _class + '">' + contract_period + '</a></li>');
    });
  }

  renderPrices (data) {
    // Update the dom with new prices
    $('.js__upgrade-period').html( data.contract_period )
    $('.js__loan-period').html( data.order.loan_period )
    $('.js__monthly-cost').html( data.estimated_monthly_cost )
    $('.js__starting-fee').html( data.order.starting_fee )
    $('.js__bank-service-fee').html( data.monthly_service_fee )
    $('.js__interest-rate').html( data.order.annual_interest_rate )
    $('.js__effective-interest-rate').html( data.order.effective_interest_rate )
    $('.js__total-value-of-cart').html( data.total_value_of_cart )
    $('.js__amount-paid-at-upgrade').html( data.order.amount_paid_at_upgrade )
    $('.js__amount-paid-without-upgrade').html( data.order.total_amount_without_upgrade )

    $('.js__additional_cost').val(data.order.additional_cost_cents / 100.0)
    $('.js__additional_cost_cents').val(data.order.additional_cost_cents)
    $('.js__additional_cost_reason').val(data.order.additional_cost_reason)

    $('.js__discount').val(data.order.discount_cents / 100.0)
    $('.js__discount_cents').val(data.order.discount_cents)
    $('.js__discount_reason').val(data.order.discount_reason)

    $('.js__lender-specific-insurance-cost-monthly').html( data.lender_specific_pricing.insurance_cost_monthly  )
    $('.js__lender-specific-insurance-cost-total').html( data.lender_specific_pricing.insurance_cost_total )

    $('.js__lender-specific-dll-insurance-cost-monthly').html( data.lender_specific_pricing.dll_insurance_cost_monthly  )
    $('.js__lender-specific-dll-insurance-cost-total').html( data.lender_specific_pricing.dll_insurance_cost_total )

    if(data.coupon_engine_specific["Coupon::RenewItPlusWithoutInsuranceEngine"]){
      let engine = data.coupon_engine_specific["Coupon::RenewItPlusWithoutInsuranceEngine"]
      $('.renew_it_plus--without-insurance-names').html(engine.insurance_addons.join(", "));
    }
   
    $('.js__products-addons-name').html( data.order_items.map((x) => { return x.addons.map((y) => { return `- Includes ${y.name}<br>` }).join("") }).join("") )

    this.renderAllowedContractPeriods(data);

    // Calculate the upgrade bar width
    let percentage = (data.contract_period / data.order.loan_period) * 100

    // Don't allow widths between 81% and 99% as the bar could overlap the loan_end_month label
    // 100% is OK since the upgrade_month and loan_end_month labels are the same
    percentage = (percentage > 80 && percentage < 100) ? 80 : percentage;

    // Update bar css
    $('.upgrade-bar__progress').css('width', percentage + '%')

    // Loop through and update all order items
    $.each(data.order_items , function(index, item) {
      const $item = $('.cart-product[data-order-item-id=' + item.id + ']')

      // Check that the item exists in the dom and update prices
      if($item.length > 0) {
        $item.find('.js__product-monthly-cost').html( item.monthly_price )
        $item.find('.js__product-monthly-cost-all').html( item.monthly_price_all )
        $item.find('.js__product-value').html( item.price )
        $item.find('.js__product-residual-value').html( item.residual )
        $item.find('.js__product-upgrade-period').html( item.contract_period )
        $item.find('.js__product-addons-sku').html( item.addons.map((x) => { return x.sku }).join(" and ") )
      }
    })

    this.addEventListeners()
  }

  updateTopbarCartCount () {
    let count = $('.cart-product').length
    $('.steps__cart-count').html(count)
  }

  convertToCents (input) {
    const amount = input.replace(',', '.')
    return Math.round(parseFloat( amount ) * 100) || 0
  }

  destroy () {
    this.removeEventListeners()
  }
}

export default Cart
