class OnlineTradeInGradingForm{
  constructor(grading_form, css_selector){
    this.grading_form = grading_form;
    this.grading_form.sort((a ,b) => {
      return a.order_number - b.order_number
    })
    this.questions = this.grading_form.map(question => new RemoteTradeInGradingFormQuestion(question));
    css_selector.append(this.html());
  }

  change(func){
    this.questions.map(x => x.change(func));
  };

  val(){
    var answers = [];
    this.questions.forEach((question) => {
      answers.push(question.val())
    });
    return {answers: answers};
  };

  html(){
    var box = $('<div/>',{});
    this.questions.forEach(function(question){ box.append(question.html()) });
    return box;
  }
}

class RemoteTradeInGradingFormQuestion{
  constructor(question){
    this.question = question;
    
    let info_cards = [];
    
    info_cards = info_cards.concat(this.question.hint)
    info_cards = info_cards.concat([{"Text":this.question.text, "Images": []}])

    let answers =this.question.options;

    this.info_text = new RemoteTradeInGradingFormQuestionInfoText(info_cards);
    this.answers = new RemoteTradeInGradingFormQuestionAnswers(answers);
  }

  change(func){
    this.answers.change(func);
  }

  val(){
    return {
      question_id: this.question.id,
      option_id: this.answers.val()
    }
  }

  html(){
    return this.info_text.html(this.question.text, this.answers.html())
  }
};

class RemoteTradeInGradingFormQuestionInfoText{
  constructor(info_cards){
    this.info_cards = info_cards;
  };

  html(question_name, answers_html){
    var box = $('<div/>', { class: 'cart-grading-form__question'});
    var info_box = $('<div/>', { style: 'display: inline-block; width:100%; position:relative' });
    var info_text = $('<div/>', { class: 'bounded-options__explanation', style: 'display:none' });

    var question_box = $('<div/>', { class: 'bounded-options__question' }).append($('<label>', { text: question_name }))
    question_box.append($('<p/>', {class: 'bounded-options__read-more bounded-options__read-more--expand', text: 'Read more and see examples', click: function(){
      $(this).toggleClass('bounded-options__read-more--hide bounded-options__read-more--expand')
      $(this).text($(this).text() == 'Read more and see examples' ? 'Hide description' : 'Read more and see examples');
      info_text.toggle();
    }}));

    info_box.append(question_box)
    info_box.append(answers_html)

    this.info_cards.forEach(function(info_card){
      info_text.append(info_card);
    });

    box.append(info_box);
    return box.append(info_text);
  };
}

class RemoteTradeInGradingFormQuestionAnswers{
  constructor(answers) {

    this.answers = answers;
   
    this.change_func = function(){};

  };

  change(func){
    this.change_func = func;
  };

  val(){
    return this.value;
  }

  html(){
    this.element = $('<div/>', { class: "bounded-options__box"});

    this.answers.sort((a,b) => {return a.value ? -1 : 1 }).forEach((answer) => {
      var answer_element = $('<div/>', { class: "bounded-options__option-space"});
      answer_element.append($('<div/>', { class: "bounded-options__option", text: answer.value ? 'Yes' : 'No'}));
      answer_element.on('click', () => {
        this.element.find('.bounded-options__option').removeClass('bounded-options__option--active');
        this.element.find('.bounded-options__option').removeClass('bounded-options__option--green')
        answer_element.find('.bounded-options__option').addClass('bounded-options__option--active');
        if(answer.value){
          answer_element.find('.bounded-options__option').addClass('bounded-options__option--green');
        }
        this.value = answer.id;
        this.change_func();
      })
      this.element.append(answer_element);
      if(!answer.value) {

        answer_element.click()
      }
    });


    return this.element;
  };
};

export default OnlineTradeInGradingForm;
