import $ from 'jquery'
import './SelectizeWithCustomPlugin'

class TradeInDeviceSearch {
  constructor (container, api_url, trade_in_widget, form, device_id_field, options = {}) {
    this.container = container;
    this.api_url = api_url;
    this.selectize = null;
    this.trade_in_widget = trade_in_widget;
    this.form = form;
    this.device_id_field = device_id_field;
    this.spinnerSelector = options.spinnerSelector || '.device-search-box__spinner';
    this.spinnerActiveClass = options.spinnerActiveClass || 'device-search-box__spinner--visible';
    this.trade_in_campaigns = new TradeInCampaigns($('.trade-in-campaigns'), this.trade_in_widget);

    form.submit(function(e){
      if(!device_id_field.val()){
        e.preventDefault();
        alert("Please select your device first");
        return true;
      }
    });
  }

  loadAvailableCampaigns(id){
    $.post("/trade_ins/search/" + id + "/campaign", function( campaigns ) {
      this.trade_in_campaigns.showCampaigns(campaigns);
    }.bind(this));
  }

  show () {
    this.selectize = this.container.selectize({
      plugins: ['no_results'],
      valueField: 'Id',
      maxItems: 1,
      labelField: 'ProductName',
      searchField: ['ProductName', 'SearchQuery'],
      searchConjunction: 'or',
      create: false,

      render: {
        option: function(item, escape) {
          return `<div>
                    <span class="title">
                      <span class="name"> ${ escape(item.ProductName) } </span>
                    </span>
                  </div>`;
        }
      },

      onChange: function(value) {
        this.trade_in_campaigns.remove();

        if(value == ''){
          this.trade_in_widget.reset_product();
        }else{
          let s = this.selectize;
          var item = s.options[value];

          this.trade_in_widget.set_product(item.PictureUrl, item.ProductName, "" + item.Price + " " + item.CurrencySymbol)
          this.device_id_field.val(item.Id);
          this.loadAvailableCampaigns(item.Id);

          $('.selectize-input .item').click(function(){
            s.removeOption(item.Id);
          });
        }
      }.bind(this),

      load: function(query, callback) {
        this.showSpinner();
        $('#selectize-no-results-found-box').hide();

        if (!query.length) return callback();

        let url = this.api_url  + '?q=' + encodeURIComponent(query);
        if(this.api_url.includes("?")){
          url = this.api_url  + '&q=' + encodeURIComponent(query);
        }

        $.ajax({
          url: url,
          error: function() {
            callback();
          },
          success: function(res) {
            this.hideSpinner();
            this.selectize.clearOptions()
            let results = res.Data.slice(0, 30);

            if(results.length == 0){
              $('#selectize-no-results-found-box').show();
            }

            callback(results);
          }.bind(this)
        });
      }.bind(this)
    })[0].selectize;
  }

  showSpinner(){
    $(this.spinnerSelector).addClass(this.spinnerActiveClass);
  }

  hideSpinner(){
    $(this.spinnerSelector).removeClass(this.spinnerActiveClass);
  }
}

export default TradeInDeviceSearch
