import $ from 'jquery'

class AddOrderItem {

  constructor (options = {}) {
    this.openAddOrderItemSelector = options.openAddOrderItemSelector || '.cart-products__add-product-toggle';
    this.addOrderItemContentSelector = options.addOrderItemContentSelector || '.cart-products__add-product-hidden-content';
    this.addOrderItemSaveBtnSelector = options.addOrderItemSaveBtnSelector || '.add-product__save_btn';

    // Add event listeners when the dom is ready
    $(this.addEventListeners.bind(this))
  }

  addEventListeners () {
    $(this.openAddOrderItemSelector).on('click.AddOrderItem', this.toggleAddOrderItemBox.bind(this))
    $(this.addOrderItemSaveBtnSelector).on('click.AddOrderItem', this.saveOrderItem.bind(this))
  }

  toggleAddOrderItemBox(){
    $(this.addOrderItemContentSelector).toggle()
  }

  convertToCents (input) {
    const amount = input.replace(',', '.')
    return Math.round(parseFloat( amount ) * 100) || 0
  }

  saveOrderItem(e){
    e.preventDefault();

    let form = $(e.target).closest('.cart-products__add-product-hidden-content')
    var attributes = this.getFormAttributes(form);

    this.submitOrderItem(attributes);
  }

  getFormAttributes(form){
    return {
      name: form.find('.add-product__product_name_input').val(),
      sku: form.find('.add-product__product_sku_input').val(),
      price_cents: this.convertToCents(form.find('.add-product__product_price_input').val()),
      tags: { dll_category: form.find('.add-product__product_dll_category_input').val() }
    }
  }

  submitOrderItem(data){
    $.ajax({
      type: 'post',
      dataType: 'json',
      url: '/order_items/add_product',
      data: data,
      complete: (jxhr,textStatus)  => {
        if(jxhr.responseJSON.errors){
          alert(jxhr.responseJSON.errors.join(', '))
        }else{
          location.reload();
        }
      }
    });
  }
}

export default AddOrderItem;
