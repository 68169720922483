import $ from 'jquery'

class TradeInItemWidget{
  constructor({blank_text = 'Select a device to see how much you can get for it.', up_to_pricing = false, blank_img_src = '/images/store/trade-in/trade-in-icon-devices.svg'}){
    this.product_selected = false;
    this.campaign_selected = false;

    this.up_to_pricing = up_to_pricing;

    this.blank_text = blank_text;
    this.blank_img_src = blank_img_src;

    this.reload();
  }

  set_product(product_img_src, product_name, product_value, up_to_pricing = true){
    this.reset_campaign();
    this.product_selected = true;
    this.product_img_src = product_img_src;
    this.product_name = product_name;
    this.product_value = product_value;
    this.up_to_pricing = up_to_pricing;

    this.reload();
  }

  set_campaign(campaign_name, campaign_value, up_to_pricing = false, campaign_top_up = false){
    this.campaign_selected = true;
    this.campaign_name = campaign_name;
    this.campaign_value = campaign_value;
    this.up_to_pricing = up_to_pricing;
    this.campaign_top_up = campaign_top_up;

    this.reload();
  }

  reset_product(){
    this.product_selected = false;

    this.reload();
  }

  reset_campaign(up_to_pricing = true){
    this.campaign_selected = false;
    this.up_to_pricing = up_to_pricing;

    this.reload();
  }

  reload(){
    if(this.product_selected){
      this.show_product();
    }else{
      this.blank();
    }
  }

  show_product(){
    let formatted_price = this.campaign_selected ? this.formatted_campaign_pricing() : "<span>" + this.product_value + "</span>";
    let text = "You'll receive " + (this.up_to_pricing ? "up to " : "") + formatted_price  +  " for your " + this.product_name
    if(this.campaign_selected && this.campaign_top_up){
      text = "You'll receive up to " + formatted_price  +  " for your " + this.product_name
    }

    $('.trade-in-item__img img').attr('src', this.product_img_src);
    $('.trade-in-item__quote p').html(text)
                                .addClass('trade-in-item__quote--selected-device');
  }

  formatted_campaign_pricing(){
    if(this.campaign_top_up){
      return "<p>" + this.product_value + "</p> <span> + " + this.campaign_value +" (" + this.campaign_name + " campaign)</span>"
    }else if (this.campaign_value){
      return "<s>" + this.product_value + "</s> <span>" + this.campaign_value +" (" + this.campaign_name + " campaign)</span>"
    }else{
      return "<span>" + this.product_value +" (" + this.campaign_name + " campaign)</span>"
    }
  }

  blank(){
    $('.trade-in-item__quote p').text(this.blank_text)
                                .removeClass('trade-in-item__quote--selected-device');
    $('.trade-in-item__img img').attr('src', this.blank_img_src);
  }
}

export default TradeInItemWidget
