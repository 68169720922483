import $ from "jquery"
import './SelectizeWithCustomPlugin'

class AlchemyTradeInSearch {
  constructor(container, api_url, device_id_field, serial_number_field, trade_in_widget, form, options = {}) {
    this.container = container
    this.api_url = api_url
    this.selectize = null;
    this.trade_in_widget = trade_in_widget
    this.form = form;
    this.device_id_field = device_id_field
    this.serial_number_field = serial_number_field
    this.spinnerSelector =
      options.spinnerSelector || ".device-search-box__spinner"
    $(this.spinnerSelector).addClass("remote-trade")
    this.spinnerActiveClass =
      options.spinnerActiveClass || "device-search-box__spinner--visible"

    form.submit(function(e){
      if(!device_id_field.val()){
        e.preventDefault();
        alert("Please select your device first");
        return true;
      }
    });
  }

  show = () => {
    this.selectize = this.container.selectize({
      plugins: ['no_results'],
      valueField: 'pricing_sku',
      maxItems: 1,
      labelField: 'name',
      searchField: ['name'],
      searchConjunction: 'or',
      create: false,
      setFirstOptionActive: true,

      render: {
        option: function(item, escape) {
          return `<div>
                    <span class="title">
                      <span class="name"> ${ escape(item.name) } </span>
                    </span>
                  </div>`;
        }
      },

      onChange: (value) => {
        if(value == ''){
          this.trade_in_widget.reset_product();
        }else{
          let s = this.selectize;
          var item = s.options[value];

          this.trade_in_widget.set_product(item.image_url, item.name, "" + item.symbol + (item.value_cents/100) + " ")
          this.device_id_field.val(item.pricing_sku);
          this.serial_number_field.val(item.serial_number);

          $('.selectize-input .item').click(function(){
            s.removeOption(item.pricing_sku);
          });
        }
      },

      load: (query, callback) => {
        this.showSpinner();
        $('#selectize-no-results-found-box').hide();

        if (!query.length) return callback();

        let url = this.api_url  + '?q=' + encodeURIComponent(query);
        if(this.api_url.includes("?")){
          url = this.api_url  + '&q=' + encodeURIComponent(query);
        }

        $.ajax({
          url: url,
          error: function() {
            callback();
          },
          success: function(res) {
            this.hideSpinner();
            this.selectize.clearOptions()
            let results = res.prices.slice(0, 30);

            if(results.length == 0){
              $('#selectize-no-results-found-box').show();
            }

            callback(results);

            if(results.length == 1){ //TODO: The control does not seem to drop down when only one item is teturned. This is a hack to show the result
              this.selectize.addItem(results[0].pricing_sku);
            }
          }.bind(this)
        });
      }
    })[0].selectize;
  }

  showSpinner = () => {
    $(this.spinnerSelector).addClass(this.spinnerActiveClass)
  }

  hideSpinner = () => {
    $(this.spinnerSelector).removeClass(this.spinnerActiveClass)
  }
}

export default AlchemyTradeInSearch
