import $ from 'jquery'

class AdvancedSearchBox {

  constructor (options = {}) {
    this.showBtnSelector = options.showBtnSelector || '.advanced-searchable__show-button'
    this.inputFieldsSelector = options.inputFieldsSelector || '.advanced-searchable__box select, .advanced-searchable__box input'
    this.searchBoxSelector = options.searchBoxSelector ||  '.advanced-searchable__box'
    this.searchBoxHiddenClass = options.searchBoxHiddenClass || 'advanced-searchable__box--hidden'
    this.showFiltersText = options.showFiltersText || 'Show more filters'
    this.hideFiltersText = options.hideFiltersText || 'Hide filters'

    // Add event listeners when the dom is ready
    $(this.addEventListeners.bind(this))
  }

  addEventListeners () {
    // Add event listeners with a namespace
    console.log($(this.showBtnSelector))
    $(this.showBtnSelector).on('click.advancedSearch', this.onShowButtonClick.bind(this))
    $(this.inputFieldsSelector).on('change.advancedSearch', this.onInputChanged.bind(this))
  }

  onShowButtonClick (e) {
    if($(this.searchBoxSelector).hasClass(this.searchBoxHiddenClass)){
      $(e.target).text(this.hideFiltersText)
    }else{
      $(e.target).text(this.showFiltersText)
    }
    $(this.searchBoxSelector).toggleClass(this.searchBoxHiddenClass)
  }

  onInputChanged (){
    $(this.searchBoxSelector).parent().submit();
  }
}

export default AdvancedSearchBox
