import $ from 'jquery'

class ReturnGrading {
  constructor (serialCheckPath) {
    this.serialCheckPath = serialCheckPath
    this.isSerialCheckLoading = false
    this.serial = null
    this.grade = null
    this.isBackupConfirmed = false
    this.isUnlockedConfirmed = false
    this.isiCloudConfirmed = false
    this.isAccessoriesConfirmed = false

    // Wait for the dom to load
    $(this.onDomLoaded.bind(this))
  }

  onDomLoaded () {
    this.addEventListeners()
    this.reset()
  }

  addEventListeners () {
    // Add event listeners with a .returngrading namespace
    $('.grading__serial-form').on('submit.returngrading', this.onSerialSearchSubmit.bind(this))
    $('.grading__options a').on('click.returngrading', this.onGradingOptionsClick.bind(this))
    $('.grading__toggle').on('change.returngrading', this.onToggleChange.bind(this))
  }

  removeEventListeners () {
    // Remove event listeners with a .returngrading namespace
    $('.grading__serial-form').off('submit.returngrading')
    $('.grading__options a').off('click.returngrading')
    $('.grading__toggle').off('change.returngrading')
  }

  reset () {
    // Hide steps and messages
    $('.grading__message').stop().slideUp(300)
    $('.grading__grading, .grading__icloud, .grading__summary').hide()

    // Reset grading options
    this.setGrade(null)

    // Update summary
    this.updateSummary()
  }

  showAllSteps () {
    $('.grading__grading, .grading__icloud, .grading__summary').show()
  }

  checkSerial () {
    // Return if we're already waiting for a response
    if(this.isSerialCheckLoading) {
      return
    }

    // Disable form
    this.disableSerialSearchForm()

    // Reset grading steps
    this.reset()

    // Save serial number and update hidden input
    this.serial = $('.grading__serial-input').val()
    $('#serial_number_or_imei').val( this.serial )

    // Load device info
    $.ajax({
      cache: false,
      context: this,
      data: { serial_number_or_imei: this.serial },
      dataType: 'json',
      method: 'GET',
      url: this.serialCheckPath
    })
    .done( function(response) {
      // Check if serial number was found and that the device is not blacklisted
      if(response.success === true) {
        this.showAllSteps()
        this.updateSummary()

        // Scroll to the next step
        $('html, body').animate({
          scrollTop: $(".grading__grading").offset().top
        }, 800);

      // Otherwise show error message
      } else {
        $('.grading__message--serial-error').html(response.error).stop().slideDown()
      }

      // Enable form
      this.enableSerialSearchForm()
    })
    .fail( function(request) {
      // Ajax error messages are handled globally

      // Enable form
      this.enableSerialSearchForm()
    })
  }

  setGrade (grade) {
    this.grade = grade

    // Update hidden input
    $('#grading').val(this.grade)

    // Get reference to all options
    let $options = $('.grading__options a')

    // Hide messages
    $('.grading__options .grading__message').stop().slideUp(300)

    // Remove selected class from all options
    $options.removeClass('options-list__selected')

    // Select new option and show related message
    if(this.grade !== null) {
      let $option = $options.filter('[data-grade="' + grade + '"]')

      $option.addClass('options-list__selected')

      $option.find('.grading__message').stop().slideDown(300)
    }

    // Update summary
    this.updateSummary()
  }

  updateSummary () {
    // Hide all summary paragraphs
    $('.grading__summary-success, .grading__summary-in-progress, .grading__summary-failed').hide()

    // Grading failed
    if(this.grade === 'c') {
      $('.grading__summary-failed').show()
      this.disableAddToCart()

    // Grading was successful
    } else if ((this.grade === 'a' || this.grade === 'b') && this.isBackupConfirmed && this.isUnlockedConfirmed && this.isiCloudConfirmed && this.isAccessoriesConfirmed) {
      $('.grading__summary-success').show()
      this.enableAddToCart()

    // Grading is in progress
    } else {
      $('.grading__summary-in-progress').show()
      this.disableAddToCart()
    }
  }

  enableAddToCart () {
    $('.grading__add-to-cart').prop('disabled', false)
  }

  disableAddToCart () {
    $('.grading__add-to-cart').prop('disabled', true)
  }

  enableSerialSearchForm () {
    this.isSerialCheckLoading = false
    $('.grading__serial-button').prop('value', 'Continue')
    $('.grading__serial-button').prop('disabled', false)
  }

  disableSerialSearchForm () {
    this.isSerialCheckLoading = true
    $('.grading__serial-button').prop('value', 'Loading...')
    $('.grading__serial-button').prop('disabled', true)
  }

  onSerialSearchSubmit (e) {
    e.preventDefault()
    this.checkSerial()
  }

  onGradingOptionsClick (e) {
    e.preventDefault()

    let $option = $(e.currentTarget)

    // Check if the clicked option is selected
    if($option.hasClass('options-list__selected')) {
      this.setGrade(null)
    } else {
      this.setGrade($option.data('grade'))
    }
  }

  onToggleChange (e) {
    const $toggle = $(e.currentTarget)
    const value = $toggle.is(':checked')

    if($toggle.hasClass('grading__toggle--backup')) {
      this.isBackupConfirmed = value

    } else if($toggle.hasClass('grading__toggle--unlocked')) {
      this.isUnlockedConfirmed = value

    } else if($toggle.hasClass('grading__toggle--icloud')) {
      this.isiCloudConfirmed = value

    } else if($toggle.hasClass('grading__toggle--accessories')) {
      this.isAccessoriesConfirmed = value
    }

    this.updateSummary()
  }

  destroy () {
    this.removeEventListeners()
  }
}

export default ReturnGrading
