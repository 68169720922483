class SerialNumberForm {
  constructor({
    form,
    serialNumberInput,
    searchPath,
    confirmContinue = false,
    ignoreErrors = true,
  }) {
    this.form = form;
    this.serialNumberInput = serialNumberInput;
    this.searchPath = searchPath;
    // confirmContinue: ask for confirmation to continue if serial number not found in central registry
    this.confirmContinue = confirmContinue;
    // ignoreErrors: continue if serial number not found in central registry
    this.ignoreErrors = ignoreErrors;

    this.addEventListener();
  }

  addEventListener() {
    this.form.submit(
      (e) => {
        e.preventDefault();
        var query = this.serialNumberInput.val();

        $.ajax({
          async: false,
          url: this.searchPath + '?q=' + encodeURIComponent(query),
          error: (err) => {
            if (this.ignoreErrors) {
              console.error(err); // Ignoring this error
              err.status = 0; // Prevents ajaxError from triggering
              e.currentTarget.submit();
            } else {
              var message = (err.responseJSON && err.responseJSON.error) ? err.responseJSON.error.message : null;
              if (message) {
                alert(message);
                err.status = 0; // Prevents ajaxError from triggering
              }
            }
          },
          success: (res) => {
            if (!res.Data || res.Data.length == 0) {
              // no search results for IMEI/serial number
              if (this.confirmContinue) {
                var confirmation = confirm(
                  "Couldn't find this serial number/IMEI in the central registry. Are you sure you provided the correct Serial Number/IMEI ?"
                );
                if (confirmation) {
                  e.currentTarget.submit();
                }
              } else {
                e.currentTarget.submit();
              }
            } else {
              e.currentTarget.submit();
            }
          }
        })
      }
    );
  }
}

export default SerialNumberForm;
