import $ from 'jquery'

class ProductSearch {

  constructor (options = {}) {
    this.contract_periods = options.contract_periods || [12,24,36]
    this.showTickers = options.showTickers || false
    this.showPaymentMethods = options.showPaymentMethods || false
    this.hideMonthlyPrices = options.hideMonthlyPrices || false
    this.productTicker = options.productTicker
    this.productSearchSidebar = options.productSearchSidebar || null
    this.formSelector = options.formSelector || '.products-table__search'
    this.inputSelector = options.inputSelector || '.products-table__search input'
    this.productsSelector = options.productsSelector || '.products-table__products'
    this.spinnerSelector = options.spinnerSelector || '.products-table__spinner'
    this.spinnerActiveClass = options.spinnerActiveClass || 'products-table__spinner--visible'
    this.clearSelector = options.clearSelector || '.products-table__clear'
    this.clearActiveClass = options.clearActiveClass || 'products-table__clear--visible'
    this.tagSelector = options.tagSelector || '.search-tags__tag'
    this.emptySearchMessage = 'Sorry, no products matched your search. Please try a different search term.'
    this.searchXhr = null
    this.searchTimer = null

    // Add event listeners when the dom is ready
    $(this.addEventListeners.bind(this))
  }

  addEventListeners () {
    if(this.productSearchSidebar){
      this.productSearchSidebar.triggeredSearch($(this.inputSelector).val())
    }
    // Add event listeners with a .productsearch namespace
    $(this.formSelector).on('submit.productsearch', this.onFormSubmit.bind(this))
    $(this.inputSelector).on('input.productsearch', this.onSearchInput.bind(this))
    $(this.clearSelector).on('click.productsearch', this.onClearClick.bind(this))
    $(this.tagSelector).on('click.productsearch', this.onTagClick.bind(this))
  }

  removeEventListeners () {
    // Remove event listeners with the .productsearch namespace
    $(this.formSelector).off('submit.productsearch')
    $(this.inputSelector).off('input.productsearch')
    $(this.clearSelector).off('click.productsearch')
    $(this.tagSelector).off('click.productsearch')
  }

  onFormSubmit (e) {
    // Prevent form from submitting
    e.preventDefault()
  }

  onSearchInput (e) {
    // Get search query from the input
    const query = $(e.currentTarget).val()

    // Show the spinner for immediate feedback
    this.showSpinner()

    // Toggle clear visibility
    if(query.length > 0) {
      this.showClear()
    } else {
      this.hideClear()
    }

    // Clear any existing timers
    if(this.searchTimer) {
      window.clearTimeout(this.searchTimer)
    }

    if(this.productSearchSidebar){
      this.productSearchSidebar.triggeredSearch(query)
    }

    // Delay the search to throttle requests
    this.searchTimer = window.setTimeout(this.search.bind(this, query), 250)
  }

  onClearClick (e) {
    e.preventDefault()

    // Clear input
    $(this.inputSelector).val('').trigger('input')
  }

  onTagClick (e) {
    e.preventDefault()

    const $tag = $(e.currentTarget)
    const tagQuery = $tag.data('query')
    let currentQuery = $(this.inputSelector).val()
    let newQuery = null

    // Check if we should replace the current query
    if($tag.hasClass('search-tags__tag--replace') || $tag.data('replace-search') == true) {
      newQuery = tagQuery

    // Otherwise add to the existing query
    } else {
      // Remove existing storage from the original query
      // TODO Maybe keep track of the left and right side of the query instead?
      currentQuery = currentQuery.replace(/\s64|\s128|\s256|\s512/g, '')

      newQuery = currentQuery + ' ' + tagQuery
    }

    if($tag.hasClass('search-tags__tag--hidden')){
      if(this.productSearchSidebar){
        this.productSearchSidebar.triggeredSearch(newQuery)
      }
      this.search(newQuery);
    }else{
      // Update input
      $(this.inputSelector).val(newQuery).trigger('input')
    }
  }

  search (query) {
    // Abort any pending reqests to ensure that the latest query gets rendered last, e.g. when typing fast
    if(this.searchXhr) {
      this.searchXhr.abort()
    }

    // Update url with search query without refreshing the page.
    // This is needed as you're redirected to the referrer when you add a product to the cart.
    this.updateUrl(query)

    this.fetchSearchResults(query)
  }

  fetchSearchResults(query = "") {
    // Fetch search results
    this.searchXhr = $.ajax({
      cache: false,
      context: this,
      data: { query: query },
      dataType: 'json',
      method: 'GET',
      url: '/products.json'
    })
    .done( function(response) {
      // Hide the spinner
      this.hideSpinner()

      // Clear previous search results
      $(this.productsSelector).empty()

      // Render products
      if(response.length > 0) {
        response.map(this.renderProduct, this)
      } else {
        $(this.productsSelector).append(`<div class="products-table__empty">${ this.emptySearchMessage }</div>`)
      }
    })
    .fail( function(request) {
      // Ignore the error if it's a request we aborted
      if(request.status === 0) {
        return
      }

      // Hide the spinner
      this.hideSpinner()

      // Ajax errors messages are handled globally
    })
  }

  renderProduct (product, idx) {
    function renderMonthlyPrice(price){
      if(price){
        return '' + price + '<span>/mo.</span>'
      }else{
        return '-'
      }
    }

    function constructPriceTickers(){
      if(this.showPaymentMethods){
        return Object.keys(product.payment_methods).map(function(method){
          return `
          <div class="products-table__price">
            <h4>${method}</h4>
            <p>${ renderMonthlyPrice(product.payment_methods[method]) }</p>
          </div>
          `
        }).join("")
      }else{
        if(this.hideMonthlyPrices){
          return ''
        }else{
          return this.contract_periods.map(function(period){
            return `
            <div class="products-table__price">
              <h4>${period} months</h4>
              <p>${ renderMonthlyPrice(product.contract_periods[period]) }</p>
            </div>
            `
          }).join("")
        }
      }
    }

    const monthlyPriceTickers = constructPriceTickers.bind(this)();

    let tickers = ''
    if(this.showTickers == true){
      tickers = `
        <div class="products-table__tickers-container">
          <div class="products-table__tickers">
            <div class="products-table__tickers-add">
            </div>
            <div>
              <input class="products-table__tickers-count" value="1">
            </div>
            <div class="products-table__tickers-remove">
            </div>
            <div class="products-table__tickers-add-btn">
              Add
            </div>
          </div>
        </div>
      `
    }

    let algolia_search_params = '';
    if(product.query_id){
      algolia_search_params = `&${ ($.param({ algolia: { object_id: product.id, position: idx + 1, query_id: product.query_id }} )) }`;
    }
    // Create element
    const element = `
      <a href="/order_items/add?count=1&product_id=${ product.id }${algolia_search_params}" class="products-table__product">
        <div class="products-table__product-row">
          <div class="products-table__product-info">
            <div class="products-table__image-container">
              <img src="${ product.images.image_256 }" alt="${ product.name }">
            </div>

            <h3>${ product.name }</h3>

            <p class="products-table__sku">${ product.sku }</p>
          </div>

          ${ monthlyPriceTickers }

          <div class="products-table__price">
            <h4>Compare to</h4>
            <p>${ product.price }</p>
          </div>

          ${ tickers }
        </div>
      </a>
    `

    // Inject product into dom
    $(this.productsSelector).append(element)

    if(this.showTickers == true){
      this.productTicker.removeEventListeners();
      this.productTicker.addEventListeners();
    }
  }

  showSpinner () {
    $(this.spinnerSelector).addClass(this.spinnerActiveClass)
  }

  hideSpinner () {
    $(this.spinnerSelector).removeClass(this.spinnerActiveClass)
  }

  showClear () {
    $(this.clearSelector).addClass(this.clearActiveClass)
  }

  hideClear () {
    $(this.clearSelector).removeClass(this.clearActiveClass)
  }

  updateUrl (query) {
    history.pushState(null, null, '/products?query=' + query)
  }

  destroy () {
    this.removeEventListeners()
  }
}

export default ProductSearch
