import $ from 'jquery'

class OrderItemUpdatePrice {

  constructor (options = {}) {
    this.url = options.url;
    this.excluding_vat = options.excluding_vat;
    this.openProductPriceInputSelector = options.openProductPriceInputSelector || '.action__edit_product_price';
    this.updateProductPriceSelector = options.updateProductPriceSelector || '.update-product__save_btn';
    this.inputProductPriceSelector = options.inputProductPriceSelector || '.update-product__price_cents_input';

    // Add event listeners when the dom is ready
    $(this.addEventListeners.bind(this))
  }

  addEventListeners () {
    $(this.openProductPriceInputSelector).on('click.open_product_price', this.onOpenInputBox.bind(this))
    $(this.updateProductPriceSelector).on('click.update_product_price', this.updateProductPrice.bind(this))
    $(this.inputProductPriceSelector).on('keydown.submit_product_price', this.onInputEnterSubmit.bind(this))
  }

  onInputEnterSubmit(event){
    if(event.keyCode == 13) { // Enter BTN
      event.preventDefault();

      let submit_btn = $(event.target).closest('.update-product__price').find(this.updateProductPriceSelector);

      this.submitPriceChange($(event.target).val(), submit_btn.data('product-id'))
    }
  }

  convertToCents (input) {
    const amount = input.replace(',', '.')
    return Math.round(parseFloat( amount ) * 100) || 0
  }

  onOpenInputBox(e) {
    let box = $(e.currentTarget).closest('.cart-product');

    box.find('.cart-product__change_price').show();
    box.find('.action__hide_on_product_change').hide();
  }

  updateProductPrice(e){
    let target = $(e.currentTarget)
    let cents_value = target.closest('.update-product__price').find('.update-product__price_cents_input').val()

    this.submitPriceChange(cents_value, target.data('product-id'));
  }

  submitPriceChange(cents, product_id){
    $.ajax({
          type: 'post',
          url: this.url,
          data: { 'new_product_price_cents': this.convertToCents(cents),
                  'product_id': product_id,
                  'excluding_vat': this.excluding_vat },
          complete: ()  => {
            window.location.reload();
          }
    });
  }
}

export default OrderItemUpdatePrice;
