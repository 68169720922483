import $ from 'jquery'

$(() => {
  const phoneFormat = () => {
    if($(".phone-input__country").data('country-code') =='us') {
      var number = String($(".phone").val() || '').replace(/\D/g, '')
      .replace(/^1/, '')
      .replace(/^(\d)/, '($1')
      .replace(/^(\(\d{3})(\d)/, '$1) $2')
      .replace(/(\d{3})(\d{1,4})/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1');
      $(".phone").val(number)
    }
  }
  phoneFormat();
  $('.phone').on('input', phoneFormat);

  $('.birthdate').on('input', function() {
    var birthdate = $(this).val().replace(/[^\d]/g, '')
    if(birthdate.length === 2) {
      birthdate = birthdate.replace(/(\d{2})/, "$1");
    }
    if(birthdate.lenth === 4 ) {
      birthdate = birthdate.replace(/(\d{2})(\d{2})/, "$1/$2");
    }
    if(birthdate.length === 7) {
      birthdate = birthdate.replace(/(\d{1})(\d{2})(\d{4})/, "$1/$2/$3");
    }
    if(birthdate.length === 8) {
      birthdate = birthdate.replace(/(\d{2})(\d{2})(\d{4})/, "$1/$2/$3");
    }
    
    $(this).val(birthdate)
  })

})
