import $ from 'jquery'

class ProductOrderLenderSelector {

  constructor (options = {}) {
    this.lenderSelector = options.lenderSelector || '#preferred-lender'
    // Add event listeners when the dom is ready
    $(this.addEventListeners.bind(this))
  }

  addEventListeners () {
    this.selector().on('change', this.updateOrderLenderSelector.bind(this))
  }

  removeEventListeners () {
    this.selector().off('change', this.updateOrderLenderSelector.bind(this))
  }

  selector() {
    return $(this.lenderSelector)
  } 

  updateOrderLenderSelector() {

    let order_id = this.selector().data('order-id')

    this.updateOrder = $.ajax({
      cache: false,
      context: this,
      data: {lender: this.selector().val() },
      dataType: 'json',
      method: 'PATCH',
      url: `/plans/${order_id}/update_lender.json`

    })
    .done( function(response) {
        productSearch.fetchSearchResults()
    })
    .always( function(error) {
      // hide spinner
      this.hideSpinner()
    })
  }

  showSpinner () {
    $(this.spinnerSelector).addClass(this.spinnerActiveClass)
  }

  hideSpinner () {
    $(this.spinnerSelector).removeClass(this.spinnerActiveClass)
  }

  destroy () {
    this.removeEventListeners()
  }
}

export default ProductOrderLenderSelector
