class TradeInDeviceDropdownSearch{
  constructor(questions, devices, trade_in_widget){
    this.questions = questions;
    this.devices = devices;
    this.trade_in_widget =  trade_in_widget;

    this.question_boxes = [];

    Object.keys(questions).forEach(function(aa){
      var question = new TradeInDeviceFeatureDropdown(questions[aa]);
          question.change(this.change.bind(this))
      this.question_boxes.push(question);
    }.bind(this));


    this.question_boxes[0].fill(this.options_for(0))
  }

  change(question_sort){
    Object.keys(this.values()).forEach(function(key){
      $('#trade_in_create_upgraded_trade_in_form_' + key).val(this.values()[key])
    }.bind(this));

    for (let i = question_sort + 1; i <= this.question_boxes.length - 1; i++) {
      this.question_boxes[i].empty();
    };

    if(question_sort == this.question_boxes.length - 1){
      var product_name = Object.keys(this.values()).map(function(x){ return this.values()[x] }.bind(this)).join(" / ")

      var item = this.find_result();

      this.trade_in_widget.set_product(item.image_url, product_name, "" + (item.value_cents_a / 100.0) + " " + item.currency);
    }else{
      this.trade_in_widget.reset_product();

      this.question_boxes[question_sort + 1].fill(this.options_for(question_sort + 1));
    }
  }

  find_result(){
    var result = {};
    this.devices.forEach(function(device){
      var valid = true;

      Object.keys(this.values()).forEach(function(key){
        if(device[key] != this.values()[key]){
          valid = false;
        }
      }.bind(this))

      if(valid){
        result = device;
      }
    }.bind(this));

    return result;
  };

  options_for(step){
    var options = [];

    this.devices.forEach(function(device){
      var valid = true;

      Object.keys(this.values()).forEach(function(key){
        if(device[key] != this.values()[key]){
          valid = false;
        }
      }.bind(this))

      if(valid){
        options.push(device[this.questions[step].type])
      }
    }.bind(this));

    return options;
  }

  values(){
    var val = {};

    this.question_boxes.forEach(function(question){
      if(question.val() != '' && question.val() != undefined){
        val[question.question.type] = question.val();
      }
    });

    return val;
  }

  html(){
    var box = $('<div/>', {});

    this.question_boxes.forEach(function(question){
      box.append(question.html())
    });

    return box;
  }
}



class TradeInDeviceFeatureDropdown{
  constructor(question){
    this.question = question;

    this.construct_html();
  }

  construct_html(){
    this.box = $('<div/>', { class: "device-dropdown-search__select" });
    this.box.append($('<label/>', { text: this.question.name }));
    this.select_box = $('<select/>', {});
    this.select_box.change(function(){
      this.value = this.select_box.val();
      this.change_func(this.question.sort);
    }.bind(this));
    this.box.append(this.select_box);
  }

  val(){
    return this.value;
  }

  change(func){
    this.change_func = func;
  }

  empty(){
    this.value = '';
    this.select_box.html('');
  }

  fill(options){
    this.select_box.append($('<option/>', { value: '', text: 'Select a ' + this.question.type }));
    new Set(options).forEach(function(option){
      this.select_box.append($('<option/>', { value: option, text: option}));
    }.bind(this));
  };

  html(){
    return this.box;
  }
}

export default TradeInDeviceDropdownSearch;
