import $ from 'jquery'

class SigneeCombinationOption {
  constructor () {
    // Add event listeners when the dom is ready
    $(this.addEventListeners.bind(this))
  }

  addEventListeners () {
    this.removeEventListeners()

    $('.signee-option').on('click', this.showEmailInputs.bind(this))
    $('.signee_email_addresses__input').on('input', this.onEmailInput)
  }

  onEmailInput () {
    let input = $(this).val()
    let regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    let validEmail = regex.test(input);
    let signeeCount = $(this).parent().find('.signee_email_addresses__input').length

    const formIdentifier = "#loan_application_dll_form"
    const firstNameField = $(`${formIdentifier}_first_name`);
    const lastNameField = $(`${formIdentifier}_last_name`);
    const emailField = $(`${formIdentifier}_email`);
    let emptyFields = (firstNameField.val() == "" && lastNameField.val() == "" && emailField.val() == "");

    if(signeeCount == 1 && validEmail && emptyFields){
      firstNameField.val($(this).data("firstname"));
      lastNameField.val($(this).data("lastname"));
      emailField.val($(this).val())
    }
  }

  removeEventListeners () {
    // Remove event listeners with the .cart namespace
    $('.signee-option').off('click')
    $('.signee_email_addresses__input').off('input')
  }

  showEmailInputs (e) {
    $('.signee-option__inputs').hide();
    $(e.currentTarget).next('.signee-option__inputs').toggle();

    $('.signee-option').removeClass('signee-option__checked');
    $(e.currentTarget).toggleClass('signee-option__checked');
  }
}

export default SigneeCombinationOption;
