class TradeInCampaigns{
  constructor(container, trade_in_widget){
    this.container = container;
    this.trade_in_widget = trade_in_widget;
    this.campaigns = [];
  }

  showCampaigns(campaigns){
    this.remove();
    this.campaigns = campaigns.map((campaign) => { return new TradeInCampaign(campaign, this.trade_in_widget, this) });
    this.container.append(this.to_html());
  }

  show(campaign){
    this.remove();
    this.campaigns = [new TradeInCampaign(campaign, this.trade_in_widget, this)];
    this.container.append(this.to_html());
  }

  remove(){
    $("#trade-in-campaign-id").val("");
    this.container.html('');
  }

  to_html(){
    let box = $('<div/>',{});

    this.campaigns.forEach(function(campaign){
      box.append(campaign.to_html());
    });

    return box;
  }
}

class TradeInCampaign{
  constructor(campaign, trade_in_widget, campaigns){
    this.campaign = campaign;
    this.active = false;
    this.trade_in_widget = trade_in_widget;
    this.campaigns = campaigns;
    this.options_box;
  }

  set_active(){
    this.campaigns.campaigns.forEach((x) => { if(x != this){ x.set_inactive() } })
    this.active = true;
    $("#trade-in-campaign-id").val(this.campaign.id);

    if(this.campaign.keep_standard_trade_in_price){
      this.trade_in_widget.set_campaign(this.campaign.name, null, true);
    }else{
      this.trade_in_widget.set_campaign(this.campaign.name, this.campaign.top_up_amount_value, false, this.campaign.top_up);
    }
  }

  set_inactive(){
    if(this.options_box){
      this.options_box.find('.bounded-options__option').removeClass('bounded-options__option--active bounded-options__option--green');
      this.options_box.find('.bounded-options__option').each((i,x) => {
        if($(x).text() == "No"){
          $(x).addClass("bounded-options__option--active");
        }
      });
    }
    this.active = false;
    $("#trade-in-campaign-id").val("");
    this.trade_in_widget.reset_campaign();
  }

  to_html(){
    let that = this;
    let campaign = this.campaign;
    let box = $('<div/>',{ class: "trade-in-campaign" });
    let question = $('<div/>', { class: "bounded-options__question" });
    let info_text = $('<div/>', { class: "bounded-options__explanation", style: "display:none", text: this.campaign.html_explanation });
    let top_up_question = "Do you want to top up the price with the " + this.campaign.name + " campaign?"
    if(this.campaign.top_up_question){
      top_up_question = this.campaign.top_up_question;
    }

    question.append($('<label/>', { text: top_up_question, class: "trade-in-campaign--label" }));
    question.append($('<p/>',{ class: "bounded-options__read-more bounded-options__read-more--expand", text: "See what this means", click: function(){
      $(this).toggleClass('bounded-options__read-more--hide bounded-options__read-more--expand')
      $(this).text($(this).text() == 'See what this means' ? 'Hide description' : 'See what this means');
      info_text.toggle();
    }}));
    box.append(question);
    box.append(info_text);

    let options_box = $('<div/>', { class: "bounded-options__box" });
    ["No", "Yes"].forEach(function(answer){
      let option = $('<div/>', { class: "bounded-options__option", text: answer });
      if(answer == "No"){option.addClass("bounded-options__option--active")}
      options_box.append($('<div/>', {class: "bounded-options__option-space"}).append(option));

      option.click(function(){
        options_box.find('.bounded-options__option')
                   .removeClass('bounded-options__option--active bounded-options__option--green');

        $(this).addClass('bounded-options__option--active');
        $("#trade-in-campaign-id").val("");
        if($(this).text() == "Yes"){
          that.set_active();
          $(this).addClass('bounded-options__option--green');
        }else{
          that.set_inactive();
        };
      });
    });

    this.options_box = options_box;

    box.append(options_box);
    return box;
  }
}

export default TradeInCampaigns;
