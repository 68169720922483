import $ from 'jquery'

class ProductTicker {

  constructor (options = {}) {
    this.addBtnSelector = options.addBtnSelector || '.products-table__tickers-add'
    this.removeBtnSelector = options.removeBtnSelector || '.products-table__tickers-remove'
    this.counterSelector = options.counterSelector || '.products-table__tickers-count'
    this.applyBtnSelector = options.applyBtnSelector || '.products-table__tickers-add-btn'
    this.productSelector = options.productSelector || '.products-table__product'
    // Add event listeners when the dom is ready
    $(this.addEventListeners.bind(this))
  }

  addEventListeners () {
    // Add event listeners with a .productticker namespace
    $(this.addBtnSelector).on('click.productTicker', this.onAdd.bind(this))
    $(this.removeBtnSelector).on('click.productTicker', this.onRemove.bind(this))
    $(this.applyBtnSelector).on('click.productTicker', this.onApply.bind(this))
    $(this.counterSelector).on('click.productTicker', this.onCountInputChange.bind(this))
  }

  onCountInputChange (e) {
    e.preventDefault();
  }

  onAdd (e) {
    e.preventDefault();

    let count = $(e.currentTarget).parent().find(this.counterSelector)
    count.val(Math.max(parseInt(count.val()) - 1, 1))
  }

  onRemove (e) {
    e.preventDefault();

    let count = $(e.currentTarget).parent().find(this.counterSelector)
    count.val(Math.min(parseInt(count.val()) + 1, 100))
  }

  onApply (e) {
    e.preventDefault();

    let product_link = $(e.currentTarget).closest(this.productSelector).attr('href')
    let count = $(e.currentTarget).parent().find(this.counterSelector).val()
    let new_product_link = product_link.replace(/(count=).*?(&)/,'$1' + count + '$2');

    window.location.href = new_product_link
  }

  removeEventListeners () {
    $(this.addBtnSelector).off('click.productTicker')
    $(this.removeBtnSelector).off('click.productTicker')
    $(this.applyBtnSelector).off('click.productTicker')
    $(this.counterSelector).off('click.productTicker')
  }
}

export default ProductTicker
