import $ from 'jquery'

// Example usage in an ERB page

//   <% if Rails.env.development? %>
//     <div class="dev">
//       <div class="dev__icon">
//       <svg viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
//         <path fill-rule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
//       </svg>
//       </div>
//       <div class="dev__content">
//         <h1>Dev Mode</h1>
//         <p>Fill in <a href="#" class="fill-and-submit" data-email="random#email">random email</a>.</p>
//         <p>Sign in as <a href="#" class="sign-in-as" data-email="retailer@example.com" data-user-type="store">Retailer</a>.</p>
//       </div>
//     </div>
//   <% end %>

class Dev {
  constructor (options = {}) {
    this.fillUSAddressLinkSelector = '.fill-us-address-link';
    this.fillUSBusinessApplicationLinkSelector = '.fill-us-business-application-link';
    this.fillSEAddressLinkSelector = '.fill-se-address-link';

    // Add event listeners when the dom is ready
    $(this.addEventListeners.bind(this))
  }

  addEventListeners () {
    $(this.fillUSAddressLinkSelector).on('click', this.fillUSAddress.bind(this))
    $(this.fillSEAddressLinkSelector).on('click', this.fillSEAddress.bind(this))
    $(this.fillUSBusinessApplicationLinkSelector).on('click', this.fillUSBusinessApplication.bind(this))

    $('.sign-in-as').on('click', this.signInAs.bind(this))
    $('.fill-and-submit').on('click', this.fillAndSubmit.bind(this))
    $('.fill-form').on('click', this.fillForm.bind(this))
  }

  signInAs (event) {
    // Get the clicked element
    const element = event.target;

    // Get the data attributes
    const email = element.dataset.email;
    const userType = element.dataset.userType;

    // Fill in the email and password fields
    document.getElementById(`${userType}_user_email`).value = email;
    document.getElementById(`${userType}_user_password`).value = 'password';

    // Submit the form
    document.getElementById(`new_${userType}_user`).submit();
  }

  fillUSAddress () {
    var element = document.getElementById('recipient_name');
    var phoneNumber = String( Math.floor(Math.random() * 9000000000) + 1000000000 );
    
    if (element) {
      element.value = 'Harry Potter';
    } else {
      document.getElementById('first_name').value = 'Harry';
      document.getElementById('last_name').value = 'Potter';
    }

    element = document.getElementById('email');
    if (element) {
      element.value = 'test@example.com';
    }
    
    element = document.getElementById('contact_email');
    if (element) {
      element.value = 'test@example.com';
    }

    element = document.getElementById('contact_phone_number');
    if (element) {
      element.value = phoneNumber;
    } else {
      document.getElementById('phone_number').value = phoneNumber;
    }

    document.getElementById('address_line_1').value = '23074 Lowridge Place';
    document.getElementById('address_line_2').value = '';
    document.getElementById('city').value = 'Santa Clarita';
    document.getElementById('state').value = 'CA';
    document.getElementById('zip_code').value = '91390';
    element = document.getElementById('country_code');
    if (element) {
      element.value = "us";
    }
  }

  fillUSBusinessApplication () {
    document.getElementById('web_site').value = 'https://example.com';
    document.getElementById('DUNS_number').value = '3485726345';
    document.getElementById('ABM_id').value = '445588';

    document.getElementById('organization_name').value = 'ACME Corp';

    document.getElementById('address_line_1').value = '23074 Lowridge Place';
    document.getElementById('address_line_2').value = '';
    document.getElementById('city').value = 'Santa Clarita';
    document.getElementById('state').value = 'CA';
    document.getElementById('zip_code').value = '91390';
    document.getElementById('country_code').value = "us";

    document.getElementById('contact_name').value = 'Robert Switcher';
    document.getElementById('contact_title').value = 'CEO';
    document.getElementById('contact_email').value = 'robert@example.com';
    document.getElementById('contact_phone_number').value = '555 414 ' + + Math.floor(1000 + Math.random() * 9000);
  }

  fillSEAddress () {
    var element = document.getElementById('recipient_name');
    var phoneNumber = String( Math.floor(Math.random() * 9000000000) + 1000000000 );
    
    if (element) {
      element.value = 'Sven Svensson';
    } else {
      document.getElementById('first_name').value = 'Sven';
      document.getElementById('last_name').value = 'Svensson';
    }

    element = document.getElementById('email');
    if (element) {
      element.value = 'test@example.se';
    }
    
    element = document.getElementById('contact_email');
    if (element) {
      element.value = 'test@example.se';
    }

    element = document.getElementById('contact_phone_number');
    if (element) {
      element.value = phoneNumber;
    } else {
      document.getElementById('phone_number').value = phoneNumber;
    }

    document.getElementById('address_line_1').value = 'Drottninggatan 1';
    document.getElementById('city').value = 'Stockholm';
    document.getElementById('zip_code').value = '16873';
    element = document.getElementById('country_code');
    if (element) {
      element.value = "se";
    }
  }

  fillForm(event) {
    event.preventDefault();
    // Get the clicked element
    const element = event.target;

    // Get all the data attributes and fill the fields in
    Object.entries(element.dataset).forEach(([key, value]) => {
      if (value == "random#email") {
        value = Math.random().toString(36).substring(7) + "@example.com";
      }
      document.getElementById(key).value = value;
    });
  }

  fillAndSubmit(event) {
    event.preventDefault();
    // Get the clicked element
    const element = event.target;

    this.fillForm(event);

    // Find the form
    let form = document.querySelector('form');
    if (form) {
      form.submit();
    }
  }
}

export default Dev;
