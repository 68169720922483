import $ from 'jquery'

class MarketingModal {
  constructor () {
    // Wait for the dom to be ready
    $(this.onDomReady.bind(this))
  }

  onDomReady () {
    // Save references to elements
    this.$open = $('.marketing-modal__open')
    this.$close = $('.marketing-topbar__close')
    this.$container = $('.marketing-modal')
    this.$background = $('.marketing-modal__background')
    this.$dialog = $('.marketing-modal__dialog')
    this.$topbar = $('.marketing-topbar')
    this.$topbarLinks = $('.marketing-topbar__logo, .marketing-topbar nav a')
    this.$faqTitles = $('.marketing-faq dt')

    // Add event listeners when the dom is ready
    this.addEventListeners()
  }


  addEventListeners () {
    this.$open.on('click.marketingmodal', this.onOpenClick.bind(this))
    this.$close.on('click.marketingmodal', this.onCloseClick.bind(this))
    this.$background.on('click.marketingmodal', this.onBackgroundClick.bind(this))
    this.$topbarLinks.on('click.marketingmodal', this.onTopbarLinkClick.bind(this))
    this.$faqTitles.on('click.marketingmodal', this.onFaqClick.bind(this))
    $(document).on('keyup.marketingmodal', this.onDocumentKeyUp.bind(this))

    // Sticky marketing topbar
    this.$topbar.affix({
      target: '.marketing-modal',
      offset: {
        top: parseInt( $('.marketing-modal__dialog').css('top') )
      }
    })
  }

  removeEventListeners () {
    this.$open.off('.marketingmodal')
    this.$close.off('.marketingmodal')
    this.$background.off('.marketingmodal')
    this.$topbarLinks.off('.marketingmodal')
    this.$faqTitles.off('.marketingmodal')
    document.off('.marketingmodal')
  }

  onOpenClick (e) {
    e.preventDefault()
    this.openModal()
  }

  onCloseClick (e) {
    e.preventDefault()
    this.closeModal()
  }

  onBackgroundClick (e) {
    e.preventDefault()
    this.closeModal()
  }

  onTopbarLinkClick (e) {
    e.preventDefault()

    const $link =  $(e.currentTarget)
    const $scrollTo = $( $link.attr('href') )
    const dialogOffset = parseInt( this.$dialog.css('top') ) // This differs between resolutions
    const offset = - 72 + dialogOffset
    const scrollTop = $scrollTo.position().top + offset

    // Calculate speed based on distance
    let speed = Math.abs( this.$container.scrollTop() - scrollTop ) * 0.75
    speed = Math.max(speed, 500)
    speed = Math.min(speed, 4000)

    // Animate scroll position
    this.$container.stop().animate({
      scrollTop: scrollTop
    }, 1200)
  }

  onFaqClick (e) {
    e.preventDefault()
    const $dt = $(e.currentTarget)
    const $dd = $dt.next('dd')

    $dt.add($dd).toggleClass('marketing-faq__open')
  }

  onDocumentKeyUp (e) {
    // Pressing escape hides the modal
    if(e.keyCode === 27) {
      this.closeModal()
    }
  }

  openModal () {
    // Reset modal scroll position to 0
    if(this.$container.scrollTop() > 0) {
      this.$container.scrollTop(0)
    }

    // Disable body scrolling
    $('body').addClass('body--disable-scroll')

    // Show modal
    this.$container.addClass('marketing-modal--visible')
  }

  closeModal () {
    // Enable body scrolling
    $('body').removeClass('body--disable-scroll')

    // Hide modal
    this.$container.removeClass('marketing-modal--visible')
  }
}

export default MarketingModal
