import $ from 'jquery'

class DisableButtonsWhileSigning{

  constructor(resource){
    this.resource = resource;
    $(this.onDomLoaded.bind(this))
  }

  onDomLoaded () {
    this.addEventListeners()
  }

  addEventListeners () {
    this.disableLinksWhileSigning();
  }

  disableLinksWhileSigning () {
    const event_name = 'click.disable-while-signing';
    const non_disabled_links_class = ".a--non-disable-while-signing";
    const warning_message = `Are you sure you don’t want to sign the contract? The ` + this.resource + ` will be deleted if you leave this page without signing the contract.`;

    $('a').not(non_disabled_links_class).on(event_name, function(e) {
      e.preventDefault();
      alert(warning_message);
      $('a').off(event_name);
    });
  }
}

export default DisableButtonsWhileSigning
