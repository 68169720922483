import $ from 'jquery'
import 'intl-tel-input'

class IntlInput {
  constructor($inputs) {
    this.$inputs = $inputs
  }

  activate() {
    // Country code inputs
    this.$inputs.each( function(el) {
      // Get current input value
      let val = $(this).val()
      let dialing_codes = { 'se': '+46', 'no': '+47', 'fi': '+358', 'dk': '+45', 'us': '+1', 'gb': '+44' }
      let onlyCountries = $(this).data('only-countries')
      let allowDropdown = $(this).data('allow-dropdown')

      if(!val) {
        val = dialing_codes[$(this).data('country-code')]
      }

      // Prepend + if there's a value so that the country plugin can detect the country
      else if (val && val.charAt(0) !== '+') {
        val = '+' + val
      }
      // Leave values with a + as they are
      else {
        val = val
      }

      // Update the input value. The country plugin automatically uses this to set the active country.
      $(this).val(val)

      // Initialize the country plugin
      $(this).intlTelInput({
        autoPlaceholder: 'off',
        preferredCountries: Object.keys(dialing_codes),
        separateDialCode: true,
        onlyCountries: onlyCountries,
        allowDropdown: allowDropdown,
      })

      // The country plugin constructor clears the input value so we need to set it again
      $(this).val(val)

      // Update the hidden input onchange.
      // This won't happen automatically as we've set separateDialCode to true (to get visible country codes)
      $(this).on('countrychange', function(e, countryData) {
        $(this).val('+' + countryData.dialCode)
      })
    })
  }
}

export default IntlInput
