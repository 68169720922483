import $ from 'jquery'

class QuestionModal {

  constructor(options={}){
    this.$container = $(options.modal);
    this.$reloadOnClose = options.reloadOnClose || false;

    // Wait for the dom to be ready
    $(this.onDomReady.bind(this))
  }

  onDomReady () {
    // Save references to elements
    this.$close = this.$container.find('.question-modal__close, .question-modal__close-button, .question-modal__background')

    // Add event listeners when the dom is ready
    this.addEventListeners()

    // Open modal
    this.open()
  }

  addEventListeners () {
    this.$close.on('click.questionmodal', this.onCloseClick.bind(this))
  }

  removeEventListeners () {
    this.$close.off('.questionmodal')
  }

  onCloseClick (e) {
    e.preventDefault()
    this.close()
    if(this.$reloadOnClose){
      location.reload()
    }
  }

  open () {
    // Disable body scrolling
    $('body').addClass('body--disable-scroll body--modal-is-visible')

    // Show modal
    this.$container.addClass('question-modal--visible')
  }

  close () {
    // Enable body scrolling
    $('body').removeClass('body--disable-scroll body--modal-is-visible')

    // Hide and remove modal
    this.$container.removeClass('question-modal--visible')
  }
}

export default QuestionModal
