// Handle errors
import '../shared/errors'

// Import jquery
window.$ = window.jQuery = require('jquery')

// Add Rails CSRF token to every XHR
window.$.ajaxSetup({
  beforeSend: function(xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) }
})

import Rails from 'rails-ujs'
Rails.start()

// Import Bootstrap plugins
require('bootstrap-sass/assets/javascripts/bootstrap/affix')

// Sticky Lib
require('sticky-sidebar-v2/dist/jquery.sticky-sidebar.js')

// PostHog
import posthog from 'posthog-js'
window.Posthog = posthog;

// Import our classes
import ProductSearch from './ProductSearch'
import ProductSearchSidebar from './ProductSearchSidebar'
import AdvancedSearchBox from './AdvancedSearchBox'
import ProductTicker from './ProductTicker'
import '../checkout/phoneInput.js';
import IntlInput from '../shared/IntlInput'
import Cart from './Cart'
import Dev from './dev'
import SigneeCombinationOption from './SigneeCombinationOption'
import ReturnGrading from './ReturnGrading'
import LoanApplicationStatus from './LoanApplicationStatus'
import SignContractStatus from './SignContractStatus'
import XhrPool from '../shared/XhrPool'
import SignReturnStatus from './SignReturnStatus'
import ProductOrderLenderSelector from './ProductOrderLenderSelector'
import MarketingModal from './MarketingModal'
import WetSignatureForm from './WetSignatureForm'
import DisableButtonsWhileSigning from './DisableButtonsWhileSigning'
import QuestionModal from './QuestionModal'
import AlchemyTradeInSearch from './trade-in/AlchemyTradeInSearch.js'
import OnlineTradeInGradingForm from './trade-in/OnlineTradeInGradingForm.js'
import TradeInItemWidget from './trade-in/TradeInItemWidget'
import TradeInDeviceDropdownSearch from './trade-in/TradeInDeviceDropdownSearch'
import TradeInGradingForm from './trade-in/TradeInGradingForm'
import TradeInDeviceSearch from './trade-in/TradeInDeviceSearch'
import TradeInCampaigns from './trade-in/TradeInCampaigns'
import SerialNumberForm from './trade-in/SerialNumberForm'
import OrderItemUpdatePrice from './OrderItemUpdatePrice'
import AddOrderItem from './AddOrderItem'
import { GoogleAutoComplete } from '../checkout/googleAutoComplete'

// Make classes accessible from rails views
window.ProductSearch = ProductSearch
window.ProductSearchSidebar = ProductSearchSidebar
window.AdvancedSearchBox = AdvancedSearchBox
window.ProductTicker = ProductTicker
window.ProductOrderLenderSelector = ProductOrderLenderSelector
window.Cart = Cart
window.Dev = Dev
window.IntlInput = IntlInput
window.LoanApplicationStatus = LoanApplicationStatus
window.SigneeCombinationOption = SigneeCombinationOption
window.SignContractStatus = SignContractStatus
window.XhrPool = XhrPool
window.SignReturnStatus = SignReturnStatus
window.ReturnGrading = ReturnGrading
window.MarketingModal = MarketingModal
window.WetSignatureForm = WetSignatureForm
window.QuestionModal = QuestionModal
window.AlchemyTradeInSearch = AlchemyTradeInSearch
window.OnlineTradeInGradingForm =  OnlineTradeInGradingForm
window.TradeInItemWidget = TradeInItemWidget
window.TradeInDeviceDropdownSearch = TradeInDeviceDropdownSearch
window.TradeInGradingForm = TradeInGradingForm
window.TradeInDeviceSearch = TradeInDeviceSearch
window.TradeInCampaigns = TradeInCampaigns
window.SerialNumberForm = SerialNumberForm
window.DisableButtonsWhileSigning = DisableButtonsWhileSigning
window.OrderItemUpdatePrice = OrderItemUpdatePrice
window.AddOrderItem = AddOrderItem
window.googleAutoComplete = GoogleAutoComplete

// Ensure that browsers without support for console.log don't throw errors
window.console = window.console || { "log": function(log) {} };

// Updates visibility of the "other cancellation_reason_text" textarea when cancelling orders
function updateCancelOrderForm () {
  const val = $(".cancel-order-radio-button:checked").val()

  if(val === 'other') {
    $('.cancel-order__other').fadeIn();
  } else {
    $('.cancel-order__other').hide();
  }
}

// Runs when the dom is ready
$(function () {
  // Mobile navigation toggle
  $('.top-bar__navigation-toggle').on('click', function(e) {
    e.preventDefault()
    $('.top-bar__navigation-content').toggle()
  })

  // Cart bar select toggle
  $('.cart-bar__select').on('click', function(e) {
    // Don't prevent default as it has nested links. This component should be refactored at some point.
    $(e.currentTarget).toggleClass('cart-bar__select--active')
  })

  $('.toggle-retailer-switch').on('click', function(e) {
    e.preventDefault()
    $('.topbar__overflow-toggle')[0].click();
  });

  // Toggles
  $('.content-toggle').on('click', function(e) {
    e.preventDefault()

    const $toggle = $(e.currentTarget)
    const $content = $( $toggle.attr('href') )

    $content.stop().fadeToggle(300)
  })

  // Update visibility of the "other cancellation_reason_text" textarea when the dom is ready
  updateCancelOrderForm()

  // Update visibility of the "other cancellation_reason_text" textarea when checking a radio button
  $(".cancel-order-radio-button").on('change', updateCancelOrderForm)

  // Sandbox actions
  $('.sandbox-actions a:not(.sandbox-actions__do-not-prevent-default)').on('click', function(e) {
    e.preventDefault()

    // Get url
    const url = $(this).attr('href')

    // Replace links with a loading message
    $(this).closest('.sandbox-actions').html('Please wait...')

    // Send the request with ajax instead
    $.ajax({
      cache: false,
      context: this,
      method: 'GET',
      url: url
    })
    .done( function(response) {
      // No need to anything here, the poll will get the new status once it's done
    })
    .fail( function(error) {
      // Ajax errors are handled globally
    })
  })

  // Custom checkboxes
  $('.custom-checkbox').each(function() {
    const $checkbox = $(this)
    const $span = $checkbox.children('span')

    // Add a class to the checkbox if it contains a single line of text
    if($span.length > 0 && $span.height() <= 24) {
      $checkbox.addClass('custom-checkbox--single-line')
    }

    // Display the checkbox
    $(this).addClass('custom-checkbox--visible')
  })

  // FAQ toggle
  $('.knowledge-bar__faq dt').on('click', function(e){
    e.preventDefault()
    const $dt = $(e.currentTarget)
    const $dd = $dt.next('dd')

    $dt.add($dd).toggleClass('knowledge-bar__open')
  })

  // Country code inputs
  new IntlInput($('.phone-input__country')).activate()

  // Marketing modal
  new MarketingModal()

  // Report when in an iFrame
  function inIframe () {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  if (inIframe) {
    // So parent can remember & resume a session after client navigates away from it
    window.parent.postMessage({type: "locationChanged", url: window.location.href}, "*");
  }
})
