import $ from 'jquery'

class LoanApplicationStatus {
  constructor (options = {}) {
    this.loanApplicationStatus = undefined;
    this.loanApplicationErrorMessage = options.loanApplicationErrorMessage || null;
    this.getStatusPath = options.getStatusPath || ''
    this.redirectToPath = options.redirectToPath || ''
  }

  poll () {
    $.ajax({
      cache: false,
      context: this,
      dataType: 'json',
      method: 'GET',
      url: this.getStatusPath
    })
    .done( function(response) {
      if(response.isLoanApplicationDone) {
        window.location = this.redirectToPath
      } else if((!(this.loanApplicationStatus === undefined) && (this.loanApplicationStatus != response.loanApplicationStatus)) || (this.loanApplicationErrorMessage != response.loanApplicationErrorMessage)){
        location.reload(true);
      } else {
        this.loanApplicationStatus = response.loanApplicationStatus;
        this.loanApplicationErrorMessage = response.loanApplicationErrorMessage;
        setTimeout(this.poll.bind(this), 2000)
      }
    })
    .fail( function(error) {
      // Ajax errors are handled globally
    })
  }
}

export default LoanApplicationStatus
