class TradeInGradingForm{
  constructor(grading_form, css_selector){
    this.grading_form = grading_form;
    this.questions = this.grading_form.Questions.map(x => new TradeInGradingFormQuestion(x));
    this.extras =  this.grading_form.Extras.map(x => new TradeInGradingFormQuestion(x));

    css_selector.append(this.html());
  }

  change(func){
    this.questions.concat(this.extras).map(x => x.change(func));
  };

  val(){
    var answers = {};
    this.questions.forEach(x => answers = $.extend(answers, x.val()) );

    var included_extras = [];
    this.extras.forEach(function(extra){
      if(extra.val()[extra.question.Id] == 0){
        included_extras.push(extra.question.Id);
      }
    });

    return {answers: answers, extras: included_extras};
  };

  html(){
    var box = $('<div/>',{});
    this.questions.concat(this.extras).forEach(function(question){ box.append(question.html()) });
    return box;
  }
}

class TradeInGradingFormQuestion{
  constructor(question){
    this.question = question;

    let info_cards = [];
    if(this.question.InfoCards){
      info_cards = info_cards.concat(this.question.InfoCards)
    }
    if(this.question.Info != ""){
      info_cards = info_cards.concat([{"Text":this.question.Info, "Images": []}])
    };

    let answers = [{"Id": 0, "Name": "Yes", "Score": 100, "InfoCards": [], "IsVendorSelected": false, "IsPartnerSelected": false, "IsAffiliateSelected": false}, {"Id": 1, "Name": "No", "Score": 5, "InfoCards": [], "IsVendorSelected": false, "IsPartnerSelected": false, "IsAffiliateSelected": false}];
    if(this.question.Answers){
      answers = this.question.Answers;
    }

    this.info_text = new TradeInGradingFormQuestionInfoText(info_cards);
    this.answers = new TradeInGradingFormQuestionAnswers(answers);
  }

  change(func){
    this.answers.change(func);
  }

  val(){
    var val = {}
    val[this.question.Id] = this.answers.val();

    return val;
  }

  html(){
    return this.info_text.html(this.question.Name, this.answers.html())
  }
};

class TradeInGradingFormQuestionInfoText{
  constructor(info_cards){
    this.info_cards = info_cards;
  };

  html(question_name, answers_html){
    var box = $('<div/>', { class: 'cart-grading-form__question'});
    var info_box = $('<div/>', { style: 'display: inline-block; width:100%; position:relative' });
    var info_text = $('<div/>', { class: 'bounded-options__explanation', style: 'display:none' });

    var question_box = $('<div/>', { class: 'bounded-options__question' }).append($('<label>', { text: question_name }))
    question_box.append($('<p/>', {class: 'bounded-options__read-more bounded-options__read-more--expand', text: 'Read more and see examples', click: function(){
      $(this).toggleClass('bounded-options__read-more--hide bounded-options__read-more--expand')
      $(this).text($(this).text() == 'Read more and see examples' ? 'Hide description' : 'Read more and see examples');
      info_text.toggle();
    }}));

    info_box.append(question_box)
    info_box.append(answers_html)

    this.info_cards.forEach(function(info_card){
      info_text.append(info_card.Text);
      info_card.Images.forEach(function(image){
        info_text.append($('<img/>', {src: image }));
      });
    });

    box.append(info_box);
    return box.append(info_text);
  };
}

class TradeInGradingFormQuestionAnswers{
  constructor(answers) {
    this.answers = answers;
    this.change_func = function(){};

  };

  change(func){
    this.change_func = func;
  };

  val(){
    return this.value;
  }

  html(){
    this.element = $('<div/>', { class: "bounded-options__box"});
    var highest_score = Math.max(...this.answers.map(function(x){ return x.Score}));
    var lowest_score = Math.min(...this.answers.map(function(x){ return x.Score}));

    this.answers.sort(function(a,b){return a.Score - b.Score }).forEach(function(answer){
      var answer_element = $('<div/>', {class: "bounded-options__option-space"});
      answer_element.append($('<div/>', { class: "bounded-options__option", text: answer.Name}));
      answer_element.click(function(){
        this.element.find('.bounded-options__option').removeClass('bounded-options__option--active');
        this.element.find('.bounded-options__option').removeClass('bounded-options__option--green')
        answer_element.find('.bounded-options__option').addClass('bounded-options__option--active');
        if(answer.Score == highest_score){
          answer_element.find('.bounded-options__option').addClass('bounded-options__option--green');
        }
        this.value = answer.Id;
        this.change_func();
      }.bind(this));
      this.element.append(answer_element);
      if(answer.Score == lowest_score){
        answer_element.click();
      }
    }.bind(this));

    return this.element;
  };
};

export default TradeInGradingForm;
