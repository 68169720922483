class ProductSearchSidebar {
  constructor(options = {}){
    this.topLevelLinkSelector = options.topLevelLinkSelector || '.search-tags-sidebar__link';
    this.topLevelLinkActiveClass = options.topLevelLinkActiveClass || 'search-tags-sidebar__link--active';
    this.topLevelLinkActiveSearchClass  = options.topLevelLinkActiveSearchClass || 'search-tags-sidebar__link--active-search';
    this.topLevelIdDataAttribute = options.topLevelIdDataAttribute || 'sidebar-first-level-index';
    this.secondLevelElementSelector = options.secondLevelElementSelector || '.search-tags-sidebar-second-level';
    this.secondLevelElementActiveClass = options.secondLevelElementActiveClass || 'search-tags-sidebar-second-level__element--active';
    this.secondLevelLinkElementSelector = options.secondLevelLinkElementSelector || '.search-tags-sidebar-second-level__element';
    this.secondLevelIdDataAttribute = options.secondLevelIdDataAttribute || 'sidebar-second-level-index';
    this.secondLevelContainerClass = options.secondLevelContainerClass || '.search-tags-sidebar-second-level__outer-container';
    this.secondLevelStickyClass = options.secondLevelStickyClass || '.search-tags-sidebar-second-level__sticky-box';
    this.secondLevelCloseBtnClass = options.secondLevelCloseBtnClass || '.search-tags-sidebar__close-btn';
    this.secondLevelElementShadowClass = options.secondLevelElementShadowClass || '.search-tags-sidebar-second-level__outer-container-shadow';
    this.linkQueries = this.getLinkQueries();
    this.currentQuery = null;

    $(this.addEventListeners.bind(this))
  }

  getLinkQueries(){
    let linksInfo = []
    $(this.secondLevelLinkElementSelector).each((i,element) => {
      linksInfo.push([ $(element).data('query'), $(element).data('first-level-index'), $(element)])
    })
    return linksInfo;
  }

  addEventListeners(){
    $(this.topLevelLinkSelector).on('click', function(e){
         this.onTopLevelClick(e)
    }.bind(this))

    $(this.secondLevelCloseBtnClass).click(this.hideLinks.bind(this))
    $(this.secondLevelLinkElementSelector).click(this.hideLinks.bind(this))
  }


  triggeredSearch(query){
    this.currentQuery = query;
    $(this.topLevelLinkSelector).removeClass(this.topLevelLinkActiveSearchClass);
    $(this.secondLevelLinkElementSelector).removeClass(this.secondLevelElementActiveClass);

    if(query == ''){
      $($(this.topLevelLinkSelector)[0]).addClass(this.topLevelLinkActiveSearchClass)
    }else{
      this.markMatchesSidebarLinkAsActive(query)
    }
  }

  markMatchesSidebarLinkAsActive(query){
    this.linkQueries.forEach(function(e){
      if(e[0] == query){
        let firstLevelLink = $($(`*[data-${this.topLevelIdDataAttribute}="${e[1]}"]`)[0])
        firstLevelLink.addClass(this.topLevelLinkActiveSearchClass)
        e[2].addClass(this.secondLevelElementActiveClass)
      }
    }.bind(this));
  }


  onTopLevelClick(e){
    const $link = $(e.currentTarget)
    $(this.secondLevelContainerClass).hide();
    $(this.secondLevelElementShadowClass).hide()

    if($link.hasClass(this.topLevelLinkActiveClass) || $(this.secondLevelContainerClass).is(":visible")){
      this.hideLinks();
    }else{
      this.hideFirstLevelActiveClasses()
      $link.addClass(this.topLevelLinkActiveClass)

      if(!$link.data('default')){
        this.openLink($link)
      }
    }

    if($link.data('default')){
      window.location = '/products'
    }
  }

  hideFirstLevelActiveClasses(){
    $(this.secondLevelElementShadowClass).hide();
    $(this.topLevelLinkSelector).removeClass(this.topLevelLinkActiveSearchClass);
    $(this.topLevelLinkSelector).removeClass(this.topLevelLinkActiveClass);
  }

  hideLinks(){
    this.hideFirstLevelActiveClasses()
    this.triggeredSearch(this.currentQuery);
    $(this.secondLevelContainerClass).hide();
  }

  openLink($link){
    $(this.secondLevelElementSelector).hide();
    $(this.secondLevelContainerClass).show();
    $(this.secondLevelElementShadowClass).show();
    const index = $link.data(this.topLevelIdDataAttribute);
    const activeSecondLevelElement = $(`*[data-${this.secondLevelIdDataAttribute}="${index}"]`);
    activeSecondLevelElement.show();

    var sidebar = new StickySidebar('#' + activeSecondLevelElement.attr('id'), {
        containerSelector: this.secondLevelContainerClass,
        innerWrapperSelector: this.secondLevelStickyClass
    });
  }
}

export default ProductSearchSidebar;
