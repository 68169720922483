import $ from 'jquery'

export class GoogleAutoComplete {

  constructor(options = {}) {
    this.baseURL = 'https://maps.googleapis.com/maps/api/place'
    this.elementId = options.elementId
    this.passThroughURL  = options.passThroughURL
    this.APIKEY = options.APIKEY 
    $(`#${this.elementId}`).on('input', async (event) => {
      const newPlace = await this.getPlaces(event.target.value)
      if(newPlace) {
        this.showOptions(newPlace.predictions)
      }
    })
  }

  getPlaces = async (text) => {
    if(text.length < 3) return
    const input = encodeURIComponent(text)
    return fetch(this.passThroughURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': "*",
      },
      body: JSON.stringify({
        url: `${this.baseURL}/autocomplete/json?input=${input}&region=us&types=address&key=${this.APIKEY}`
      })
    })
    .then((response) => response.json()) 
  } 
  
  getPlaceDetails = async (placeId) => {
    return fetch(this.passThroughURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': "*",
      },
      body: JSON.stringify({
        url: `${this.baseURL}/details/json?place_id=${placeId}&fields=address_components%2Cadr_address%2Cformatted_address&key=${this.APIKEY}`
      })
    }) 
  }

  onAddressClick = async (event) => {
    event.preventDefault();
    let response = await this.getPlaceDetails(event.target.id)
    response = await response.json()
    $('#prediction-container').empty()
    this.fillInAddressComponents(response.result)
  }

  hideOptions = () => {
    let predictionContainer = $('#prediction-container')
    predictionContainer.empty()
  }

  showOptions = (options) => {
    if(options.length < 1) return
    let predictionContainer = $('#prediction-container')

    if(predictionContainer.length === 0) {
      $(`#${this.elementId}`).after('<div id="prediction-container"></div>')
      predictionContainer = $('#prediction-container')
    } else {
      predictionContainer.empty()
    }

    const predictions = []
    predictions.push('<div class="close"></div>')
    options.forEach((option) => {
      predictions.push(`<div class="prediction-option" id=${option.place_id}>${option.description}</div>`)
    })
    predictionContainer.append(predictions)
    $('.prediction-option').on('click',this.onAddressClick)
    $('.close').on('click', (event) => {
      this.hideOptions()
    })
  }

  findAddressComponent = (address_components, type) => {
    return address_components.filter((component) => {
      return component.types.indexOf(type) > -1
    })[0]
  }

  fillInAddressComponents =  (results) => {
    if(!results.address_components || results.address_components.length < 1) return 
    const country = this.findAddressComponent(results.address_components, 'country')
    if(country.short_name === 'US') {
      const streetNumber = this.findAddressComponent(results.address_components, 'street_number') 
      const city = this.findAddressComponent(results.address_components, 'locality')
      const zipCode = this.findAddressComponent(results.address_components, 'postal_code')
      const state = this.findAddressComponent(results.address_components, 'administrative_area_level_1')
      $('#address_line_1').val(`${streetNumber ? streetNumber.long_name : ''} ${this.findAddressComponent(results.address_components, 'route').long_name}`)
      $('#city').val(city ? city.short_name : '')
      $('#zip_code').val(zipCode ? zipCode.short_name : '')
      $('#state').val(state ? state.short_name: '')
    }
    if(country.short_name === 'SE') {
      $('#address_line_1').val(`${results.address_components[1].long_name} ${results.address_components[0].long_name}`)
      $('#city').val(this.findAddressComponent(results.address_components, 'postal_town').short_name)
      $('#zip_code').val(this.findAddressComponent(results.address_components, 'postal_code').short_name)
    }

  }
}
