import $ from 'jquery'

class WetSignatureForm {

  constructor(){
    $(this.onDomLoaded.bind(this))
  }

  onDomLoaded () {
    this.addEventListeners()
    this.reset()
  }

  addEventListeners () {
    $('.id-form').on('submit', this.onIdentityFormSubmit.bind(this));
  }

  onIdentityFormSubmit(e) {
    e.preventDefault();
    this.reset();

    var submit_button = $('.id-form input[type=submit]');
    var form = $('.id-form').first();
    var url = form.attr('action');

    submit_button.prop('value', 'Loading...')
                 .prop('disabled', true);

    $.ajax({
      type: 'POST',
      url: url,
      data: form.serialize()
    }).done(function(data){
      if(data.errors) {
        $('.id-form__error').show();
      } else {
        this.showPrintSection(data['template_url']);
      }
    }.bind(this)).always(function(){
      submit_button.prop('value', 'Continue')
                   .prop('disabled', false);
    }.bind(this));
  }

  showPrintSection(pdf_url){
    $('.form-print__button').attr('href', pdf_url);
    $('.form-print, .confirm-sign').show();
    this.scrollTo($('.form-print'));
  }

  reset(){
    $('.form-print, .confirm-sign, .id-form__error').hide();
  }

  scrollTo(element){
    $('html, body').animate({
      scrollTop: element.offset().top
    }, 800);
  }
}

export default WetSignatureForm
