import $ from 'jquery'

class XhrPool {
  constructor () {
    this.pool = []

    $(document).ajaxSend(function(e, jqXhr, options){
      this.pool.push(jqXhr)
    }.bind(this))

    $(document).ajaxComplete(function(e, jqXhr, options) {
      this.pool = $.grep(this.pool, function(x) { return x != jqXhr } )
    }.bind(this))
  }

  abortAll () {
    $.each(this.pool, function(i, jqXhr) {
      jqXhr.abort()
    })
  }
}

export default XhrPool
